import { Network } from '../../__generated__/graphql'

export function useNetworkOptions(): { value: Network; label: string }[] {
  return Object.values(Network).map((value) => {
    return {
      value,
      label: value,
    }
  })
}
