import React from 'react'
import { useMemo } from 'react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import {
  makeLink,
  OtherColumn,
  FirstColumn,
  HookOptions,
  GroupColumnsType,
  createNumericColumn,
  formatNameWithAdditionalInfo,
} from './useStatsColumns'
import dayjs from 'dayjs'
import { PublisherCostDetails } from '../../../__generated__/graphql'
import LinkCell from '../../../components/layout/cells/LinkCell'
import StatusCell from '../../../components/layout/cells/StatusCell'
import CostDetailsTableSelectField from '../../../components/stats/table/header/CostDetailsTableSelectField'
import ExpandCellWrapper from '../../../components/table/ExpandCellWrapper'
import {
  BASE_STAT_DATA_TYPE,
  COMPANY_BASE_STAT_DATA_TYPE,
  PositionsCostDetailsTableItem,
  POSITION_DATA_TYPE,
  PUBLISHER_DATA_TYPE,
  RESELLER_PUBLISHER_DATA_TYPE,
  WEBSITE_DATA_TYPE,
} from '../../../interfaces/stats'
import { useAppTranslations } from '../useAppTranslations'
import { Column } from '../../../components/table/ReactTable'
import { useUserPermissions } from '../../../pages/company/currentCompanyDisplayedContext'

type TotalCostDetails = PublisherCostDetails & { name: string }

const positionColumnHelper = createColumnHelper<PositionsCostDetailsTableItem>()

const AdvertiserMetrics: string[] = [
  PUBLISHER_DATA_TYPE.RESELLERS,
  PUBLISHER_DATA_TYPE.CAMPAIGNS,
  POSITION_DATA_TYPE.ZONES,
  RESELLER_PUBLISHER_DATA_TYPE.ADVERTISERS,
]

const getPerformanceMetrics = (dataType: string): GroupColumnsType => {
  const isPublisherMetrics = !AdvertiserMetrics.includes(dataType)
  const impressionMetrics = isPublisherMetrics ? 'displays' : 'impressions'

  return {
    group: 1,
    columnsName: [impressionMetrics, 'ctr', 'clicks'],
  }
}

const REVENUE_METRICS: GroupColumnsType = {
  group: 2,
  columnsName: ['cpc', 'rpm', 'cpm', 'commissions'],
}

const LEAD_METRICS = {
  group: 3,
  columnsName: ['leads', 'lr', 'cpl'],
}

const ORDER_METRICS = {
  group: 4,
  columnsName: ['orders', 'or', 'cpo'],
}

const useNameColumnPublisher = <T extends string>(
  dataType: string,
  total: TotalCostDetails,
  options: HookOptions<T>
): Column => {
  const { dateFormat, selectedDataType, selectedDataTypeChange, allDataTypes } = options
  const columnHelper = createColumnHelper<
    PublisherCostDetails & { name: string; id?: string; additionalInfo?: string }
  >()

  return useMemo(
    () =>
      columnHelper.accessor('name', {
        header: () => (
          <FirstColumn>
            <CostDetailsTableSelectField
              defaultValue={selectedDataType}
              onChange={selectedDataTypeChange}
              options={allDataTypes}
            />
          </FirstColumn>
        ),
        enableSorting: false,
        cell: ({ row, getValue }) => {
          const dateOrName = getValue()
          return (
            <ExpandCellWrapper row={row}>
              {dayjs(dateOrName).isValid() && selectedDataType === BASE_STAT_DATA_TYPE.TIMESERIES ? (
                <>{dayjs(dateOrName).format(dateFormat)}</>
              ) : (
                <LinkCell
                  value={formatNameWithAdditionalInfo(dateOrName, row.original?.additionalInfo)}
                  target={makeLink({ type: selectedDataType, id: row.original?.id, row })}
                  label={dateOrName}
                />
              )}
            </ExpandCellWrapper>
          )
        },
        footer: total.name,
      }) as Column,
    [dataType, total]
  )
}

const useNumericColumnsGroup = (
  group: GroupColumnsType,
  total: TotalCostDetails
): ColumnDef<PublisherCostDetails>[] => {
  const t = useAppTranslations()
  return useMemo(
    () =>
      group.columnsName.map((columnName) =>
        createNumericColumn<PublisherCostDetails>(
          columnName,
          t(`stats.customDisplay.publisher.${columnName}`),
          group.group,
          total
        )
      ),
    [group, total]
  )
}

const useAdditionalColumnsPublisher = (dataType: string): Column[] => {
  const t = useAppTranslations()

  return useMemo(() => {
    switch (dataType) {
      case COMPANY_BASE_STAT_DATA_TYPE.WEBSITES_AND_POSITIONS:
      case WEBSITE_DATA_TYPE.POSITIONS:
        return [
          positionColumnHelper.accessor('channelType', {
            header: () => <OtherColumn>{t('stats.table.channelType') as string}</OtherColumn>,
          }),
          positionColumnHelper.accessor('status', {
            header: t('stats.table.status') as string,
            cell: (info) => {
              if (!info.getValue()) return ''
              return <StatusCell status={info.getValue()} />
            },
          }),
        ] as Column[]
      default:
        return []
    }
  }, [dataType])
}

export const usePublisherStatsColumns = <T extends string>(
  dataType: string,
  total: TotalCostDetails,
  options: HookOptions<T>
): Column[] => {
  const { superAdmin } = useUserPermissions()
  const displayLeadAndOrderMetrics = dataType === PUBLISHER_DATA_TYPE.CAMPAIGNS && superAdmin

  const nameColumn = useNameColumnPublisher(dataType, total, options)
  const performanceColumns = useNumericColumnsGroup(getPerformanceMetrics(dataType), total)
  const revenueColumns = useNumericColumnsGroup(REVENUE_METRICS, total)
  const leadColumns = useNumericColumnsGroup(LEAD_METRICS, total)
  const orderColumns = useNumericColumnsGroup(ORDER_METRICS, total)
  const additionalColumns = useAdditionalColumnsPublisher(dataType)

  return [
    nameColumn,
    ...additionalColumns,
    ...performanceColumns,
    ...(displayLeadAndOrderMetrics ? leadColumns : []),
    ...(displayLeadAndOrderMetrics ? orderColumns : []),
    ...revenueColumns,
  ] as Column[]
}
