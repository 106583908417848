import { useLocalStorage } from '../../../utils/hooks/useLocalStorage'
import { CostDetailsFilter, GraphGranularity } from '../../../__generated__/graphql'
import { Timeframe, computeTimeframeValues } from './StatsDateRange'

export type CostDetailsTimeFilters = {
  startDate: string
  endDate: string
  granularity: GraphGranularity
  timeZone: string
  dimensionValues: CostDetailsFilter['values']
}

const defaultTimeframe = Timeframe.THIS_WEEK

export const getDefaultValues: () => CostDetailsTimeFilters = () => ({
  startDate: computeTimeframeValues()[defaultTimeframe].startDate,
  endDate: computeTimeframeValues()[defaultTimeframe].endDate,
  granularity: GraphGranularity.Day,
  timeZone: 'Europe/Paris',
  dimensionValues: [],
})

export const useCostDetailsFiltersStorage = <T extends CostDetailsTimeFilters>(
  defaultAdditional?: Partial<T>
): [value: T, setValue: (newValue: T) => void] => {
  return useLocalStorage('costDetails.filters', { ...getDefaultValues(), ...defaultAdditional } as T, [
    'dimensionValues',
  ])
}
