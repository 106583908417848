import React, { useMemo } from 'react'
import InputField, { InputFieldProps } from './InputField'
import { SxProps, TextFieldProps, Theme } from '@mui/material'
import SwitchInput, { SwitchInputProps } from './SwitchInput'
import SelectField, { SelectFieldProps } from './SelectField'
import { useEditionMode, EditionModes } from '../../utils/providers/editionModeContext'
import DateField, { DateFieldProps } from './DateField'
import RadioButtonsGroup, { RadioButtonsGroupProps } from './RadioButtonsGroup'
import AutoCompleteGoogleMapField from './autoCompleteGoogleMapField'
import { AutoCompleteGoogleMapFieldProps } from './autoCompleteGoogleMapField/intefaces'
import InputFieldArray from './InputFieldArray'
import { useHoverEditionHandlers, withHoverEditionMode } from '../../utils/withHoverEditionMode'
import DisplayedItem from './DisplayedItem'
import { useUserPermissions } from '../../pages/company/currentCompanyDisplayedContext'
import IabAutoCompleteField, { IABAutoCompleteFieldProps } from './IABAutoCompleteField'

export const labelStyle = {
  color: 'text.secondary',
  textTransform: 'uppercase',
  fontSize: 10.5,
} as const

export const valueStyle = {
  overflow: 'auto',
  display: 'flex',
  alignItems: 'center',
  pt: '3px',
  pb: '5px',
  lineHeight: '1.9rem',
  fontSize: '1.4rem',
  '& a': {
    color: 'primary.main',
    textDecoration: 'none',
    '&:hover': {
      color: 'primary.main',
      textDecoration: 'none',
    },
    '&:visited': {
      color: 'primary.main',
      textDecoration: 'none',
    },
  },
} as const
export interface TransformDisplayToEditionProps
  extends InputFieldProps,
    SwitchInputProps,
    SelectFieldProps,
    DateFieldProps,
    RadioButtonsGroupProps,
    AutoCompleteGoogleMapFieldProps,
    IABAutoCompleteFieldProps {
  text: string | null | undefined
  isLink?: boolean
  sx?: SxProps<Theme>
  inputType?:
    | 'input'
    | 'switch'
    | 'select'
    | 'datepicker'
    | 'radio'
    | 'autoCompleteGoogleMap'
    | 'inputFieldArray'
    | 'iabAutoComplete'
  editable?: boolean
  InputProps?: TextFieldProps['InputProps']
  InputLabelProps?: TextFieldProps['InputLabelProps']
  tooltipText?: string
  disableHoverEdition?: boolean
  permission?: 'read' | 'edit' | 'admin'
}

const TransformDisplayToEdition: React.FC<TransformDisplayToEditionProps> = ({
  text,
  label,
  inputType = 'input',
  editable = true,
  name,
  type,
  children,
  tooltipText,
  disableHoverEdition = false,
  textToggle,
  clearable,
  permission = 'edit',
  placeholder,
  ...props
}) => {
  const [editionModeContext, setEditionMode] = useEditionMode()
  const { setDisplayOnLeave, setEditionOnEnter, handleFocus, handleBlur } = useHoverEditionHandlers(
    name,
    disableHoverEdition
  )
  const { read, edit, admin } = useUserPermissions()

  const canEdit = useMemo(() => {
    if (editable)
      switch (permission) {
        case 'read':
          return read
        case 'edit':
          return edit
        case 'admin':
          return admin
      }

    return false
  }, [editable, read, edit, admin])

  if (!canEdit || editionModeContext === EditionModes.Display)
    return (
      <DisplayedItem
        label={label}
        isLink={props.isLink}
        tooltipText={tooltipText}
        name={name}
        onMouseEnter={canEdit ? setEditionOnEnter : undefined}
        onMouseLeave={canEdit ? setDisplayOnLeave : undefined}
        disableHoverEdition={disableHoverEdition}
        editable={canEdit}
      >
        {text ?? placeholder}
      </DisplayedItem>
    )
  else
    switch (inputType) {
      case 'input':
        return (
          <InputField
            name={name}
            placeholder={placeholder ?? 'n/a'}
            label={label}
            type={type}
            multiline={props.multiline}
            required={props.required}
            rows={props.rows}
            InputProps={props.InputProps}
            InputLabelProps={props.InputLabelProps}
            sx={{ '& label': { textTransform: 'uppercase' } }}
            onMouseLeave={setDisplayOnLeave}
            tooltipText={tooltipText}
            variant={disableHoverEdition ? 'outlined' : 'standard'}
            defaultHelperText=" "
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )
      case 'switch':
        return <SwitchInput name={name} label={label} onMouseLeave={setDisplayOnLeave} textToggle={textToggle} />
      case 'select':
        return (
          <SelectField
            required={props.required}
            name={name}
            label={label}
            withNone={props.withNone}
            multiple={props.multiple}
            sx={{ '& label': { textTransform: 'uppercase' } }}
            onMouseLeave={setDisplayOnLeave}
            tooltipText={tooltipText}
            variant={disableHoverEdition ? 'outlined' : 'standard'}
            placeholder={placeholder}
            defaultHelperText=" "
          >
            {children}
          </SelectField>
        )
      case 'datepicker':
        return (
          <DateField
            name={name}
            label={label}
            required={props.required}
            InputProps={props.InputProps}
            InputLabelProps={props.InputLabelProps}
            inputFormat={props.inputFormat}
            minDate={props.minDate}
            onMouseLeave={setDisplayOnLeave}
            sx={{ '& label': { textTransform: 'uppercase' } }}
            disableHoverEdition={disableHoverEdition}
            setEditionMode={setEditionMode}
            tooltipText={tooltipText}
            placeholder={placeholder ?? 'n/a'}
            variant={disableHoverEdition ? 'outlined' : 'standard'}
            defaultHelperText=" "
            clearable={clearable}
          />
        )
      case 'radio':
        return <RadioButtonsGroup label={label} name={name} options={props.options} onMouseLeave={setDisplayOnLeave} />
      case 'autoCompleteGoogleMap':
        return (
          <AutoCompleteGoogleMapField
            name={name}
            label={label}
            locationType={props.locationType}
            countryCode={props.countryCode}
          />
        )
      case 'inputFieldArray':
        return (
          <InputFieldArray
            name={name}
            label={label}
            type={type}
            required={props.required}
            InputLabelProps={props.InputLabelProps}
            onMouseLeave={setDisplayOnLeave}
          />
        )
      case 'iabAutoComplete':
        return (
          <IabAutoCompleteField
            label={label}
            name={name}
            required={props.required}
            onMouseLeave={setDisplayOnLeave}
            variant={disableHoverEdition ? 'outlined' : 'standard'}
            withAllOption={props.withAllOption}
            sx={{ '& label': { textTransform: 'uppercase' } }}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )
    }
}

export default withHoverEditionMode(TransformDisplayToEdition)
