import { Grid, MenuItem } from '@mui/material'
import React, { useMemo } from 'react'
import TransformDisplayToEdition from '../../../../components/form/TransformDisplayToEdition'
import HasPermission from '../../../../components/permissions/HasPermission'
import { useAdgroupChannelOptions } from '../../../../utils/hooks/adGroup/useAdgroupChannelOptions'
import { useNetworkOptions } from '../../../../utils/hooks/useNetworkOptions'
import mapEnumForSelect from '../../../../utils/mapEnumForSelect'
import {
  AdGroupCreateInput,
  AdFormat,
  AdGroupInput,
  ChannelType,
  PriorityListQuery,
} from '../../../../__generated__/graphql'
import AsyncAutoCompleteCampaignField from '../../create/AsyncAutoCompleteCampaignField'
import { useUserPermissions } from '../../../company/currentCompanyDisplayedContext'
import ButtonsField from '../../../../components/form/ButtonsField'
import { useFormikContext } from 'formik'
import { isNil } from 'lodash-es'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'

export interface InfosFieldsProps {
  adgroup: AdGroupInput | AdGroupCreateInput
  creatingAdgroup?: boolean
  canEdit?: boolean
  priorityList: PriorityListQuery['campaign']['advertiser']['reseller']['priorities']
}
const InfosFields: React.FC<InfosFieldsProps> = ({
  adgroup,
  creatingAdgroup = false,
  canEdit = true,
  priorityList,
}): JSX.Element => {
  const t = useAppTranslations()
  const { banner, web } = useUserPermissions()
  const networkOptions = useNetworkOptions()
  const channelOptions = useAdgroupChannelOptions()
  const formik = useFormikContext<AdGroupCreateInput>()
  const initialCampaignId = formik.initialValues.campaignId

  const adGroupPriorityLabel = useMemo(() => {
    if (formik.values.format === AdFormat.Banner) return t('adgroup.alwaysFirst')
    if (!isNil(formik.values.priorityId)) return priorityList.find((p) => p.id === formik.values.priorityId)?.name
  }, [formik.values.format, formik.values.priorityId, priorityList])

  const adgroupFormats = mapEnumForSelect(AdFormat).filter((format) => {
    switch (format.label) {
      case AdFormat.Banner:
        return banner
      case AdFormat.Web:
        return web
      default:
        return true
    }
  })

  const adgroupFormatsOptions = adgroupFormats.map((format, i) => (
    <MenuItem value={format.value} key={i}>
      {format.label}
    </MenuItem>
  ))

  const currentChannels = useMemo((): string[] => {
    const channels = []
    if (adgroup?.channelEmail) channels.push(ChannelType.Email)
    if (adgroup?.channelWeb) channels.push(ChannelType.Web)
    return channels
  }, [adgroup, ChannelType])

  return (
    <Grid container spacing={1}>
      {creatingAdgroup && (
        <Grid item xs={3}>
          <TransformDisplayToEdition
            type={'text'}
            name={'name'}
            required={true}
            label={'adgroup.name'}
            text={adgroup.name}
            disableHoverEdition={creatingAdgroup}
            editable={canEdit}
          />
        </Grid>
      )}
      {creatingAdgroup && (
        <HasPermission permissionRequired="banner" roleRequired={'edit'}>
          <Grid item xs={3}>
            <TransformDisplayToEdition
              inputType={'select'}
              withNone={false}
              type={'text'}
              name={'format'}
              label={'adgroup.format'}
              text={adgroup.format}
              disableHoverEdition={creatingAdgroup}
              required
              editable={canEdit}
            >
              {adgroupFormatsOptions}
            </TransformDisplayToEdition>
          </Grid>
        </HasPermission>
      )}
      {web && (
        <Grid item xs={3}>
          <TransformDisplayToEdition
            inputType="select"
            editable={creatingAdgroup}
            type="text"
            name="network"
            required={true}
            label="adgroup.network"
            text={adgroup.network?.toLowerCase() || ''}
            withNone={false}
            disableHoverEdition={creatingAdgroup}
          >
            {networkOptions.map((option, i) => (
              <MenuItem value={option.value} key={i}>
                {option.label}
              </MenuItem>
            ))}
          </TransformDisplayToEdition>
        </Grid>
      )}
      <HasPermission permissionRequired="web" roleRequired="edit">
        <Grid item xs={3}>
          <ButtonsField
            name={'channels'}
            label={'adgroup.channels'}
            options={channelOptions}
            defaultValues={currentChannels}
            editable={canEdit}
            tooltipText="adgroup.channelsHelper"
          />
        </Grid>
      </HasPermission>
      <HasPermission permissionRequired="adgroupPriority" roleRequired="edit">
        {priorityList.length > 0 && (
          <Grid item xs={3}>
            <TransformDisplayToEdition
              inputType="select"
              type="text"
              name="priorityId"
              label="commons.priority"
              text={adGroupPriorityLabel}
              editable={canEdit && formik.values.format !== AdFormat.Banner}
              disableHoverEdition={creatingAdgroup}
              withNone={false}
            >
              {priorityList.map((priority, i) => (
                <MenuItem value={priority.id} key={i}>
                  {priority.name}
                </MenuItem>
              ))}
            </TransformDisplayToEdition>
          </Grid>
        )}
      </HasPermission>
      {!creatingAdgroup && (
        <Grid item xs={3}>
          <TransformDisplayToEdition
            inputType={'inputFieldArray'}
            type={'text'}
            name={'impressionPixels'}
            label={'adgroup.details.impressionPixels'}
            text={(adgroup as AdGroupInput).impressionPixels.join('\n')}
            disableHoverEdition
            editable={canEdit}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TransformDisplayToEdition
          type={'text'}
          name={'redirectUrl'}
          required={true}
          label={'adgroup.redirectUrl'}
          text={adgroup.redirectUrl}
          multiline={true}
          tooltipText={'adgroup.redirectUrlHelper'}
          disableHoverEdition={creatingAdgroup}
          variant={creatingAdgroup ? 'outlined' : 'standard'}
          editable={canEdit}
        />
      </Grid>
      {creatingAdgroup && !initialCampaignId && (
        <Grid item xs={4}>
          <AsyncAutoCompleteCampaignField />
        </Grid>
      )}
    </Grid>
  )
}
export default InfosFields
